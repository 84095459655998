.header {
  height: 20vh;
}

.main-content {
  width: 90%;
  max-width: 800px;
  margin: 0 auto;
}

.MuiTypography-root.MuiCardHeader-title {
  font-size: .8rem;
  font-weight: 800;
}
