.conversation {
    display: flex;
    flex-direction: column;
    padding: 10px;
    min-height: 450px;
  }
  
  .typing-indicator {
    display: flex;
    justify-content: center;
    margin: 5px 0;
    padding: 10px;
    background-color: #efefef;
    color: white;
    border-radius: 20px;
    width: 50px;
  }
  
  .typing-indicator span {
    height: 10px;
    width: 10px;
    margin: 0 2px;
    background-color: #999;
    border-radius: 50%;
    display: inline-block;
    opacity: 0.4;
    /* Animation */
    animation: typing 1.5s infinite;
  }
  
  .typing-indicator span:nth-child(1) {
    animation-delay: 0s;
  }
  
  .typing-indicator span:nth-child(2) {
    animation-delay: 0.2s;
  }
  
  .typing-indicator span:nth-child(3) {
    animation-delay: 0.4s;
  }
  
  @keyframes typing {
    0% { opacity: 0.4; }
    50% { opacity: 1; }
    100% { opacity: 0.4; }
  }
  
  .message {
    max-width: 60%;
    margin: 5px 0;
    padding: 10px;
    background-color: #007aff;
    color: white;
    border-radius: 20px;
    align-self: flex-start;
    /* Animation */
    transform: scale(0);
    animation: appear 0.5s forwards;
  }
  
  @keyframes appear {
    from { transform: scale(0); }
    to { transform: scale(1); }
  }